<template>
  <div class="homeview">
    <div class="homebox" v-if="ismobile">
      <div class="conbox mobile">
        <img
          class="homeghost"
          src="../assets/sy/sy_3.png"
          alt="sy_3.png"
          width="300"
          height="400"
        />
        <div class="infobox">
          <img class="sy2" src="../assets/sy/sy_2.png" alt="sy_2.png" />
          <b>沉浸式恐怖互动小说新创作,即将上线</b>
          <p>基本详情介绍基本详情介绍基本详情介绍</p>
        </div>
      </div>
    </div>
    <div class="homebox" v-else>
      <div class="conbox">
        <img
          class="homeghost"
          src="../assets/sy/sy_3.png"
          alt="sy_3.png"
          width="300"
          height="400"
        />
        <div class="infobox">
          <img class="sy2" src="../assets/sy/sy_2.png" alt="sy_2.png" />
          <b>沉浸式恐怖互动小说新创作,即将上线</b>
          <p>基本详情介绍基本详情介绍基本详情介绍</p>
        </div>
      </div>
    </div>
    <PlayWay></PlayWay>
  </div>
</template>

<script>
import anime from "animejs";
import PlayWay from "../components/PlayWay.vue";

export default {
  name: "Home",
  props: {
    ismobile: Boolean,
  },
  components: {
    PlayWay,
  },
  data() {
    return {
      ghosttimer: null,
    };
  },
  created: function () {
    // console.log("created2222");
    // 每次进入界面时，先清除之前的所有定时器，然后启动新的定时器
    clearInterval(this.ghosttimer);
    clearTimeout(this.ghosttimer);
    this.ghosttimer = null;

    let val = require("../assets/sy/sy_1.png");
    this.$emit("showBg", val);
  },
  destroyed: function () {
    console.log("destroyed");
    // 每次离开当前界面时，清除定时器
    clearInterval(this.ghosttimer);
    clearTimeout(this.ghosttimer);
    this.ghosttimer = null;
  },
  mounted: function () {
    console.log("mouted111");
    this.initGhost();
    anime({
      targets: ".leftbox",
      translateX: [100, 0],
      opacity: [0, 1],
      complete: (anim) => {
        // anime({
        //   targets: ".homeghost",
        //   translateX: [100, 0],
        //   opacity: [0, 1],
        // });
        // this.initGhost();
      },
    });
  },
  methods: {
    initGhost() {
      console.log("initGhost");

      clearInterval(this.ghosttimer);
      clearTimeout(this.ghosttimer);
      this.ghosttimer = null;

      anime({
        targets: ".homeghost",
        scale: {
          value: [1, 3],
          duration: 1,
          easing: "steps(1)",
          // easing: "easeInOutExpo",
        },
        // opacity: {
        //   value: [1, 0],
        //   duration: 1,
        //   easing: "steps(1)",
        // },
      });

      let spacetime = Math.ceil(Math.random() * 5000) + 5000;
      // console.log("spacetime:" + spacetime);
      this.ghosttimer = setTimeout(() => {
        this.showGhost();
      }, spacetime);
    },
    showGhost() {
      console.log("showGhost");
      clearInterval(this.ghosttimer);
      clearTimeout(this.ghosttimer);
      this.ghosttimer = null;

      anime({
        targets: ".homeghost",
        // translateX: {
        //   value: function () {
        //     return anime.random(0, 1620);
        //   },
        //   duration: 10,
        //   easing: "steps(1)",
        // },
        // translateY: {
        //   value: function () {
        //     return anime.random(0, 500);
        //   },
        //   duration: 10,
        //   easing: "steps(1)",
        // },
        // scale: {
        //   value: [1, 3],
        //   duration: 1,
        //   easing: "steps(1)",
        //   // easing: "easeInOutExpo",
        // },
        opacity: {
          value: [0, 0.8],
          duration: 3000,
          easing: "linear",
        },
        complete: (anim) => {
          anime({
            targets: ".homeghost",
            opacity: [0.8, 0],
            duration: 2000,
            easing: "linear",
            complete: () => {
              let spacetime = Math.ceil(Math.random() * 5000) + 5000;

              this.ghosttimer = setTimeout(() => {
                this.showGhost();
              }, spacetime);
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.homeghost {
  position: absolute;
  left: 100px;
  top: 300px;
  z-index: 3;
  opacity: 0;
}

.homeview {
  width: 100%;
  flex-direction: column;
  .homebox {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
    z-index: 8;
    top: 0;
    display: flex;
    justify-content: center;
    .conbox {
      width: 70%;
      max-width: 950px;
      margin-top: 273px;
      position: relative;
    }
    .mobile {
      margin-top: 230px;
    }
    .infobox {
      font-size: 14px;
      font-family: Source Han Sans CN;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      img {
        max-width: 100%;
      }
      b {
        display: block;
        font-size: 24px;
        font-weight: 500;
        color: #d0fcee;
        line-height: 40px;
      }
      p {
        font-weight: 400;
        color: #738d85;
        line-height: 24px;
      }
    }
  }
}

.leftbox {
  margin-top: 273px;
}
</style>
