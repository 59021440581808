<template>
  <div class="roleview">
    <div :class="ismobile ? 'rolebox mobile' : 'rolebox'">
      <div class="rolelist">
        <span
          v-for="role in rolelist"
          class="item"
          @click="showRole(role)"
          :key="role.roleid"
        >
          <div class="box">
            <el-image
              v-if="current.Roleid == role.Roleid"
              class="info-img"
              :src="role.Avatar"
              fit="cover"
            ></el-image>
            <div
              :class="current.Roleid == role.Roleid ? 'current name' : 'name'"
            >
              {{ role.Rolename }}
            </div>
          </div>
        </span>
      </div>

      <div :class="ismobile ? 'showbox mobile' : 'showbox'">
        <img :src="current.Cover" class="cover" alt="" />
        <div class="introbox">
          <p class="nameimg">{{ current.Rolename }}</p>
          <p class="enname">{{ current.Enname }}</p>
          <p class="intro">{{ current.Intro }}</p>
          <div class="numbox">
            <p class="age">
              <span class="tag">年龄</span><span>{{ current.Age }}</span>
            </p>
            <p class="height">
              <span class="tag">身高</span><span>{{ current.Height }}CM</span>
            </p>
            <p class="weight">
              <span class="tag">体重</span><span>{{ current.Weight }}KG</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import anime from "animejs";
import webconfig from "../plugins/config";

export default {
  name: "RoleList",
  props: {
    ismobile: Boolean,
  },
  head: {
    title: function () {
      return {
        inner: "角色介绍 - 沉浸式恐怖互动小说",
      };
    },
    meta: [
      {
        name: "keywords",
        content: "角色介绍,当火车鸣笛3秒,火车鸣笛,鸣笛官网,3秒官网",
        id: "keywords",
      },
      {
        name: "description",
        content: "当火车鸣笛3秒角色介绍,沉浸式恐怖互动小说,当火车鸣笛3秒官网",
        id: "description",
      },
    ],
  },
  methods: {
    showRole: function (role) {
      this.current = role;
      this.showAnime();
    },
    showAnime: function () {
      anime({
        targets: ".showbox .cover",
        // translateX: [100, 0],
        opacity: [0, 1],
        duration: 3000,
      });
      anime({
        targets: ".showbox .introbox p",
        // translateX: [200, 0],
        opacity: [0, 1],
        duration: 3000,
        delay: anime.stagger(200), // 每个元素的延迟增加100毫秒。
      });
      anime({
        targets: ".showbox .namebox p",
        // translateX: [200, 0],
        opacity: [0, 1],
        duration: 3000,
        delay: anime.stagger(200), // 每个元素的延迟增加100毫秒。
      });
    },
  },
  mounted: function () {
    console.log("mouted111");
  },
  created: function () {
    // console.log("created2222");
    let val = require("../assets/js/js_8.png");
    this.$emit("showBg", val);

    axios.get(webconfig.APIURL + "/novel/rolelist").then(
      (res) => {
        // console.log(res);
        if (res && res.data.code) {
          // console.log(res.data);
          this.rolelist = res.data.data;
          if (this.rolelist.length > 0) {
            this.current = this.rolelist[0];
            this.showAnime();
          }
        }
      },
      (error) => {}
    );
  },
  data() {
    return {
      current: {},
      rolelist: [],
    };
  },
};
</script>

<style lang="scss">
.mainview {
  .rolebox {
    width: 80%;
    min-height: 900px;
    overflow-x: hidden;
    .rolelist {
      //height: 62px;
      display: flex;
      // flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: center;
      .item {
        width: 110px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .info-img {
          margin-top: 20px;
          height: 82px;
          width: 100%;
          padding-bottom: 7px;
          background: url("../assets/js/js_1.png") no-repeat bottom center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          display: block;
          min-width: 110px;
          color: #556963;
          font-size: 14px;
          font-weight: bold;
          background: url("../assets/js/js_2.png") no-repeat center center;
        }
        .current {
          background: none;
          color: #d0fcee;
        }
      }
    }
    .showbox {
      margin-top: 50px;
      position: relative;
      text-align: left;
      font-family: "oswald", "SourceHanSansSC-Light", "Source-Han-Light",
        "Source Han Sans CN", "SourceHanSansCN-Light", "webfontSourceHanSansSC" !important;
      .cover {
        width: 450px;
      }
      .introbox {
        position: absolute;
        top: 220px;
        left: 270px;
        right: 0;
        text-align: left;
        p {
          padding: 0;
          margin: 0;
          padding-left: 20px;
        }
        .nameimg {
          text-shadow: 0px 1px 1px #010a04;
          background: url("../assets/js/js_3.png") no-repeat top left;
          height: 48px;
          line-height: 48px;
          font-size: 36px;
          font-weight: bold;
          color: #d0fcee;
          letter-spacing: 36px;
        }
        .enname {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          color: #d0fcee;
          text-shadow: 0px 1px 1px #010a04;
          letter-spacing: 10px;
        }
        .intro {
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #738d85;
          text-shadow: 0px 1px 1px #010a04;
          text-align: left;
          padding-top: 30px;
          padding-bottom: 30px;
          min-height: 100px;
          max-width: 400px;
        }
        .numbox {
          position: relative;
          margin-left: 250px;
          right: 0;
          p {
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 20px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #d0fcee;
            line-height: 40px;
            text-align: left;
            span {
              display: inline-block;
            }
            .tag {
              font-size: 12px;
              transform: scale(0.733333);
              width: 20px;
              line-height: 12px;
              color: #738d85;
            }
          }
          .age {
            background: url("../assets/js/js_4.png") no-repeat bottom left;
            // width: 15px;
          }
          .height {
            background: url("../assets/js/js_5.png") no-repeat bottom left;
          }
          .weight {
            background: url("../assets/js/js_6.png") no-repeat bottom left;
          }
        }
      }
    }
    .mobile {
      width: 100%;
      margin-top: 20px;
      .cover {
        max-width: 100%;
      }
      .introbox {
        left: 20px;
        p {
          padding-left: 0;
          padding-right: 0;
        }
        .intro {
          max-width: 70%;
        }
        .numbox {
          margin-left: 80px;
        }
      }
    }
  }
  .mobile {
    width: auto;
    .rolelist {
      justify-content: left;
    }
  }
}
</style>
