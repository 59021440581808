"use strict";
module.exports = {
  NAME: '当火车鸣笛3秒',
  APIURL: 'http://192.168.0.201:9802',
  chats: [
    {
      id: 1,
      uid: 0,
      nickname: "灵灵",
      content: "你在收拾行李吗？",
      time: 2000,
    },
    {
      id: 2,
      uid: 1,
      nickname: "我",
      content: "嗯，你怎么知道？",
      time: 4000,
    },
    {
      id: 3,
      uid: 0,
      nickname: "灵灵",
      content: "我能看到你啊，哈哈",
      time: 3000,
      showstate: 1, //红眼
      headimg: require("../assets/qq/qq_8.png"),
    },
    {
      id: 2,
      uid: 1,
      nickname: "我",
      content: "……",
      time: 4000,
    },
    {
      id: 3,
      uid: 0,
      nickname: "灵灵",
      content: "刮风咯，你的窗户没关好",
      time: 1000,
      showstate: 2, //流血
      headimg: require("../assets/qq/qq_10.png"),
      voice: "",
    },
    {
      id: 3,
      uid: 1,
      nickname: "我",
      content: "你怎么知道？",
      time: 1000,
      showstate: 3, //掉头
    },
    {
      id: 4,
      uid: 1,
      nickname: "我",
      content: "你在我家？？",
      time: 1000,
    },
    {
      id: 5,
      uid: 1,
      nickname: "灵灵",
      content: "以前就跟你说过要记得固定窗户",
      time: 2000,
    },
    {
      id: 6,
      uid: 0,
      nickname: "灵灵",
      content: "这下好了，玻璃又碎了~",
      time: 1000,
    },
    {
      id: 7,
      uid: 1,
      nickname: "灵灵",
      content: "我手都被划伤啦",
      time: 2000,
    },
    {
      id: 8,
      uid: 1,
      nickname: "我",
      content: "请你别开玩笑了，这个一点都不好笑",
      time: 3000,
    },
    {
      id: 9,
      uid: 0,
      nickname: "灵灵",
      content: "",
      time: 0,
      img: require("../assets/qq/qq_15.png"),
    },
    {
      id: 8,
      uid: 1,
      nickname: "我",
      content: "？？",
      time: 1000,
    },
    {
      id: 8,
      uid: 1,
      nickname: "我",
      content: "？？？",
      time: 2000,
    },
    {
      id: 8,
      uid: 1,
      nickname: "我",
      content: "我怎么看不到你？？？",
      time: 3000,
    },
    {
      id: 8,
      uid: 0,
      nickname: "灵灵",
      content: "……这么多年了，你什么时候看见过我了？",
      time: 1000,
    },
    {
      id: 8,
      uid: 0,
      nickname: "灵灵",
      content: "不过，现在的你或许能看见吧",
      time: 2000,
    },
    {
      id: 8,
      uid: 0,
      nickname: "灵灵",
      content: "你关闭聊天框吧，晚点再找你聊^_^",
      time: 3000,
    },
  ]
}