<template>
  <div class="moreview" id="PlayWay">
    <div class="morebox">
      <!-- <b class="title">详情标题</b> -->
      <div class="catelist" v-for="(cate, cindex) in catelist" :key="cindex">
        <div class="title">
          <span>{{ cate.Title }}</span>
        </div>
        <el-row :gutter="20">
          <el-col
            class="item"
            :span="12"
            v-for="(item, index) in cate.WayList"
            :key="index"
          >
            <el-image class="info-img" :src="item.Img" fit="cover"></el-image>
            <p class="xq-intro">{{ item.Intro }}</p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import webconfig from "../plugins/config";

export default {
  name: "PlayWay",
  head: {
    title: function () {
      return {
        inner: "玩法介绍 - 沉浸式恐怖互动小说",
      };
    },
    meta: [
      {
        name: "keywords",
        content: "玩法介绍,当火车鸣笛3秒,火车鸣笛,鸣笛官网,3秒官网",
        id: "keywords",
      },
      {
        name: "description",
        content: "玩法介绍,沉浸式恐怖互动小说,当火车鸣笛3秒官网",
        id: "description",
      },
    ],
  },
  data() {
    return {
      catelist: [],
    };
  },
  mounted: function () {
    console.log("mouted111");
  },
  created: function () {
    // console.log("created2222");
    let val = require("../assets/js/js_8.png");
    this.$emit("showBg", val);

    axios.get(webconfig.APIURL + "/novel/playway").then(
      (res) => {
        // console.log(res);
        // console.log(res.data.data);
        this.catelist = res.data.data;
        if (this.catelist.length > 0) {
          // anime({
          //   targets: ".catelist .item",
          //   scale: [
          //     { value: 0.1, easing: "easeOutSine", duration: 500 },
          //     { value: 1, easing: "easeInOutQuad", duration: 1000 },
          //   ],
          //   delay: anime.stagger(200, { grid: [14, 5], from: "center" }),
          // });
        }
      },
      (error) => {}
    );
  },
};
</script>

<style lang="scss">
.moreview {
  display: flex;
  justify-content: center;
  width: 100%;
  // padding-top: 80px;
}
.morebox {
  width: 80%;
  max-width: 900px;
  .title {
    span {
      padding: 0 10px;
      padding-bottom: 8px;
      font-size: 36px;
      font-family: "宋体", "SourceHanSerifCN", Source Han Serif CN;
      font-weight: 800;
      color: #d0fcee;
      line-height: 40px;
      display: inline-block;
      text-shadow: 0px 1px 1px #010a04;
      background: url("../assets/js/js_1.png") repeat-x bottom center;
    }
  }
  .info-img {
    margin-top: 30px;
    width: 100%;
    max-width: 550px;
    height: 220px;
    img {
      width: 100%;
      max-width: 550px;
      height: 100%;
    }
  }
  .xq-intro {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d0fcee;
    line-height: 24px;
    text-align: left;
  }
}

.el-carousel__item {
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 100px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
}
</style>
