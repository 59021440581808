import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

// import webconfig from './plugins/config'

import 'animate.css'

// import Share from 'vue-social-share'
// import 'vue-social-share/dist/client.css';
// import '../node_modules/social-share.js/dist/js/social-share.min.js'
// Vue.use(Share)

// import Share from 'vue-social-share'
// import 'vue-social-share/dist/client.css';
// Vue.use(Share)

import VueHead from 'vue-head'
Vue.use(VueHead, {
  separator: '-',
  complement: '当火车鸣笛3秒'
})

// import HeadLoader from "vue-ext-files-head-loader";
// Vue.use(HeadLoader);

// import VueScript2 from 'vue-script2'
// Vue.use(VueScript2)

// import Share from 'vue-social-share'
// Vue.use(Share)

// import Sharethis from 'vue-sharethis'
// Vue.use(Sharethis)

// import VueSocialSharing from "vue-social-sharing";
// Vue.use(VueSocialSharing);

// import VueSocialSharing from 'vue-social-sharing'
// Vue.use(VueSocialSharing);

// import VueTypedJs from 'vue-typed-js'
// Vue.use(VueTypedJs)

// import axios from 'axios'
// import echarts from 'echarts'
// import typed from 'typed'
// import animejs from 'animejs'

// Vue.use(webconfig)

/* 路由发生变化修改页面title */
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })

Vue.config.productionTip = false

//全局导入
// Vue.prototype.$http = axios
// Vue.prototype.$echarts = echarts
// Vue.prototype.$config = webconfig

new Vue({
  router,
  render: h => h(App),
  // mounted () {
    // document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
