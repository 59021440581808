<template>
  <div>
    <div class="storybox">
      <div class="itembox" v-for="story in stories" :key="story.storyid">
        <div class="userbox">
          <el-avatar
            class="avatar"
            :src="story.Avatar"
            shape="square"
            :size="50"
          ></el-avatar>
          <div class="info">
            <b class="name">{{ story.Nickname }}</b>
            <p class="date">{{ formatDate(story.Addtime * 1000) }}</p>
          </div>
        </div>
        <p class="intro" @click="gotoDetail(story)">
          {{ story.Intro }}
        </p>
        <el-image
          :src="story.Img"
          fit="cover"
          @click="gotoDetail(story)"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import webconfig from "../plugins/config";

export default {
  name: "BackStory",
  head: {
    title: function () {
      return {
        inner: "幕后故事 - 沉浸式恐怖互动小说",
      };
    },
    meta: [
      {
        name: "keywords",
        content: "幕后故事,当火车鸣笛3秒,火车鸣笛,鸣笛官网,3秒官网",
        id: "keywords",
      },
      {
        name: "description",
        content: "当火车鸣笛3秒幕后故事,沉浸式恐怖互动小说,当火车鸣笛3秒官网",
        id: "description",
      },
    ],
  },
  mounted: function () {
    console.log("mouted111");
  },
  created: function () {
    console.log("created2222");
    let val = require("../assets/js/js_8.png");
    this.$emit("showBg", val);

    axios.get(webconfig.APIURL + "/story/list").then(
      (res) => {
        // console.log(res);
        this.stories = res.data.data;
      },
      (error) => {}
    );
  },
  data() {
    return {
      stories: "",
    };
  },
  methods: {
    //时间戳转换方法    date:时间戳数字
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD;
      // return YY + MM + DD + " " + hh + mm + ss;
    },
    gotoDetail(story) {
      // console.log(story);
      this.$router.push({
        path: "/story/detail/" + story.Storyid,
        params: story,
      });
    },
  },
};
</script>

<style lang="scss">
.storybox {
  max-width: 700px;
  width: 80%;
  .itembox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    background-color: #02140c;
    font-family: "oswald", "SourceHanSansSC-Light", "Source-Han-Light",
      "Source Han Sans CN", "SourceHanSansCN-Light", "webfontSourceHanSansSC" !important;
    .userbox {
      display: flex;
      padding: 20px;
      padding-bottom: 0;
      flex-wrap: nowrap;
      .avatar {
        width: 48px;
        height: 48px;
        background: #d0fcee;
        // opacity: 0.15;
        border-radius: 12px;
      }
      .info {
        flex-shrink: 0;
        text-align: left;
        b,
        p {
          display: block;
          margin: 0;
          padding: 0;
          padding-left: 10px;
          line-height: 25px;
        }
        .name {
          display: block;
          height: 30px;
          line-height: 30px;
          font-size: 18px;
          font-weight: bold;
          color: #d0fcee;
        }
        .date {
          height: 25px;
          line-height: 25px;
          font-size: 12px;
          font-weight: 400;
          color: #4c675e;
        }
      }
    }
    .intro {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #d0fcee;
      line-height: 26px;
      padding: 0 20px;
      margin: 5px 0;
    }
    .el-image {
      max-height: 350px;
    }
  }
}
</style>
