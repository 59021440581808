<template>
  <div class="footer">
    <div class="platform">
      <div class="platbox">
        <!-- <img src="../assets/sy/sy_12.png" alt="steam" />
      <img src="../assets/sy/sy_13.png" alt="app store" />
      <img src="../assets/sy/sy_22.png" alt="google play" /> -->
        <!-- <a href="https://store.steampowered.com/app/1563700/">
          <el-image :src="require('../assets/sy/sy_12.png')" alt="steam" fit="contain"></el-image>
        </a> -->

        <a
          href="https://apps.apple.com/cn/app/%E5%BD%93%E7%81%AB%E8%BD%A6%E9%B8%A3%E7%AC%9B%E4%B8%89%E7%A7%92/id1592834113">
          <el-image :src="require('../assets/sy/sy_13.png')" alt="app store" fit="contain"></el-image>
        </a>
        <a href="https://www.taptap.com/app/208258">
          <el-image :src="require('../assets/sy/sy_22.png')" alt="google play" fit="contain"></el-image>
        </a>
      </div>
    </div>
    <div class="companybox" v-if="ismobile">
      <div class="mobile">
        <div><img src="../assets/sy/sy_14.png" alt="jmt-logo" /></div>
        <div class="intro">
          <p>
            积木塔工作室致力于打造独立恐怖游戏分享交流平台。<br />
            希望可以带给大家更多的优质独立游戏体验
          </p>
          <p>
            Copyright © 2020 JMT. All rights Reserved. <br />
           <a href="https://beian.miit.gov.cn/">沪ICP备 2021004293号-1</a> <br />
            违法信息举报电话：021-60727072转3003
          </p>
        </div>
      </div>
    </div>
    <div class="companybox" v-else>
      <el-row class="company">
        <el-col class="left" :span="14">
          <div><img src="../assets/sy/sy_14.png" alt="jmt-logo" /></div>
          <div class="intro">
            <p>
              积木塔工作室致力于打造独立恐怖游戏分享交流平台。<br />
              希望可以带给大家更多的优质独立游戏体验
            </p>
            <p>
              Copyright © 2020 JMT. All rights Reserved. <br />
              <a style="color:#4c675e;text-decoration:none;" href="https://beian.miit.gov.cn/">沪ICP备 2021004293号-1</a>  <br />
              违法信息举报电话：021-60727072转3003
            </p>
          </div>
        </el-col>
        <el-col class="right" :span="10">
          <div><b class="title">关注我们</b></div>
          <div class="icons">
            <a href="https://space.bilibili.com/8817465" target="_blank"><img src="../assets/sy/sy_17.png"
                alt="weibo-logo" /></a>
            <a href="https://weibo.com/n/%E5%82%BB%E7%8B%97%E7%9A%84%E5%8F%94_%E7%8C%AB%E5%8F%94" target="_blank"><img
                src="../assets/sy/sy_19.png" alt="weibo-logo" /></a>
            <a href="https://space.bilibili.com/8817465" target="_blank"><img src="../assets/sy/sy_17.png"
                alt="weibo-logo" /></a>
            <a href="https://weibo.com/n/%E5%82%BB%E7%8B%97%E7%9A%84%E5%8F%94_%E7%8C%AB%E5%8F%94" target="_blank"><img
                src="../assets/sy/sy_19.png" alt="weibo-logo" /></a>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppFooter",
    props: {
      ismobile: Boolean,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .footer {
    padding-bottom: 20px;

    .mobile {
      display: block;
      text-align: center;

      .intro {
        margin: 0 20px;
      }
    }
  }

  .platform {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 2px #091f10;

    .platbox {
      width: 80%;
      display: flex;
      justify-content: center;
    }

    .el-image {
      padding: 0 20px;
    }

    // img {
    //   width: 100%;
    // }
  }

  .companybox {
    display: flex;
    justify-content: center;

    .mobile {
      margin-top: 40px;
    }

    .company {
      width: 78%;
      margin-top: 40px;
    }

    .intro {
      margin-top: 30px;

      p {
        padding: 0;
        font-size: 12px;
        line-height: 20px;
        color: #4c675e;
      }
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: left;

      .title {
        color: #d0fcee;
      }

      .icons {
        display: flex;
        margin-top: 32px;

        a {
          width: 34px;
          height: 34px;
          border: solid 2px #4c675e;
          margin-right: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        a:hover {
          border: solid 2px #d0fcee;
        }
      }
    }
  }
</style>
