<template>
  <div id="app" :style="bgbox">
    <div class="menubox" v-show="menuVisible" v-if="ismobile">
      <img class="close" @click="closeMenu()" src="./assets/ydd/read_9.png" />
      <div class="menulist">
        <span
          @click="showRoute('/')"
          class="navbg shouye"
          @mouseover="menuMouseover()"
          @mouseout="menuMenuout()"
          >首页</span
        >
        <span
          @click="toTarget('#PlayWay')"
          class="navbg xianqing"
          @hover="hover()"
          @mouseover="menuMouseover()"
          @mouseout="menuMenuout()"
          >详请介绍</span
        >
        <span
          @click="showRoute('/rolelist')"
          class="navbg juese"
          @hover="hover()"
          @mouseover="menuMouseover()"
          @mouseout="menuMenuout()"
          :ismobile="ismobile"
          >角色介绍</span
        >
        <span
          @click="showRoute('/backstory')"
          class="navbg mhgushi"
          @hover="hover()"
          @mouseover="menuMouseover()"
          @mouseout="menuMenuout()"
          >幕后故事</span
        >
      </div>
    </div>
    <div class="titlebar" v-if="ismobile">
      <div class="mobile">
        <img class="menu" src="./assets/ydd/read_7.png" @click="showMenu" />
        <img class="logo" src="./assets/sy/sy_2.png" height="63" />
      </div>
    </div>
    <div class="titlebar" v-else>
      <img class="logo" src="./assets/sy/sy_2.png" height="63" />
      <div class="menulist">
        <span @click="showRoute('/')" class="navbg shouye">首页</span>
        <span @click="toTarget('#PlayWay')" class="navbg xianqing"
          >详请介绍</span
        >
        <span @click="showRoute('/rolelist')" class="navbg juese"
          >角色介绍</span
        >
        <span
          @click="showRoute('/backstory')"
          to="/backstory"
          class="navbg mhgushi"
          >幕后故事</span
        >
      </div>
    </div>

    <!-- <div><span class="duration-demo" style="color: #fff">aaa</span></div>
    <div class="blue">bbbb</div>
    <div class="green">cccc</div> -->

    <!-- <h1 class="animate__animated animate__bounce">An animated element</h1>
    <div class="hello">
      <input type="button" value="button" @click="flag = !flag" />
      <transition
        enter-active-class="animate__animated animate__bounce"
        leave-active-class="bounceOut animated fadeOutDownBig"
        name="fade"
      >
        <div class="demo" v-show="flag">防静电辣椒粉多啦</div>
      </transition>
    </div> -->

    <!-- <QqChat @showHome="showHome"></QqChat> -->
    <router-view
      class="mainview"
      @showBg="showBg"
      :ismobile="ismobile"
    ></router-view>
    <AppFooter :ismobile="ismobile"></AppFooter>
  </div>
</template>

<script>
import axios from "axios";
import anime from "animejs";
import Home from "./views/Home.vue";
import PlayWay from "./components/PlayWay.vue";
import RoleList from "./components/RoleList.vue";
import BackSory from "./components/BackStory.vue";
import AppFooter from "./components/AppFooter.vue";
// import QqChat from "./components/QqChat.vue";
import webconfig from "./plugins/config";

export default {
  name: "app",
  components: {
    Home,
    PlayWay,
    RoleList,
    BackSory,
    AppFooter,
    // QqChat,
  },
  data() {
    return {
      bgbox: {
        background:
          "url(" + require("./assets/sy/sy_1.png") + ") no-repeat top center",
      },
      ismobile: false,
      flag: false,
      timer: null,
      menuVisible: false,
      homeVisible: false,
      PlayWayVisible: false,
      form: {
        account: "",
        passwd: "",
      },
      formLabelWidth: "70px",
    };
  },
  head: {
    title: function () {
      return {
        inner: "沉浸式恐怖互动小说",
      };
    },
    meta: [
      {
        name: "keywords",
        content: "当火车鸣笛3秒,火车鸣笛,鸣笛官网,3秒官网",
        id: "keywords",
      },
      {
        name: "description",
        content: "沉浸式恐怖互动小说,当火车鸣笛3秒官网",
        id: "description",
      },
    ],
    script: [
      {
        id: "-mob-share",
        src:
          "http://f1.webshare.mob.com/code/mob-share.js?appkey=m322ec43c6ede5",
      },
    ],
    // link: [
    //   {
    //     rel: "stylesheet",
    //     href:
    //       "https://cdn.bootcss.com/social-share.js/1.0.16/css/share.min.css",
    //   },
    // ],
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    showRoute(url) {
      this.$router.replace({
        path: url,
      });
      if (this.ismobile && this.menuVisible) {
        this.closeMenu();
      }
    },
    showBg(val) {
      //this.bgurl = val;
      this.bgbox = {
        background: "url(" + val + ") no-repeat top center",
      };
    },
    toTarget(target) {
      // console.log(target);
      // console.log(this.$router.currentRoute.path);
      if (this.ismobile && this.menuVisible) {
        this.closeMenu();
      }
      if (this.$router.currentRoute.path == "/") {
        const toElement = document.querySelector(target);
        toElement.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else {
        this.$router.push(
          {
            path: "/",
          },
          () => {
            this.$nextTick(() => {
              const toElement = document.querySelector(target);
              toElement.scrollIntoView({
                block: "start",
                behavior: "smooth",
              });
            });
          }
        );
      }
    },
    showMenu() {
      this.menuVisible = true;
      anime({
        targets: ".menubox",
        translateY: [-300, 0],
        opacity: [0, 1],
        easing: "linear",
        // easing: "easeInOutQuad",
        duration: 300,
        // delay: anime.stagger(150), // 每个元素的延迟增加100毫秒。
        complete: (anim) => {},
      });
    },
    closeMenu() {
      // this.menuVisible = false;
      anime({
        targets: ".menubox",
        translateY: [0, -300],
        opacity: [1, 0],
        easing: "linear",
        // easing: "easeInOutQuad",
        duration: 300,
        // delay: anime.stagger(150), // 每个元素的延迟增加100毫秒。
        complete: (anim) => {
          this.menuVisible = false;
        },
      });
    },
    menuMouseover() {
      console.log("mouseover_1111111111111");
    },
    menuMenuout() {
      console.log("menuout");
    },
    showHome() {
      console.log("showHome");
      this.homeVisible = true;
    },
    getData() {
      console.log("getData");
      axios.get(webconfig.APIURL + "/data.json").then(
        (res) => {
          console.log(res);
        },
        (error) => {}
      );
    },
  },
  mounted: function () {
    console.log("mouted111");
    if (this.isMobile()) {
      this.ismobile = true;
    }
  },
  created: function () {
    console.log("created");
    // 每次进入界面时，先清除之前的所有定时器，然后启动新的定时器
    // clearInterval(this.timer);
    // this.timer = null;

    // animejs({
    //   targets: ".duration-demo",
    //   translateX: 250,
    //   duration: 3000,
    // });

    // var myAnimation = anime({
    //   targets: [".blue", ".green"],
    //   translateX: "13rem",
    //   rotate: 180,
    //   borderRadius: 8,
    //   duration: 2000,
    //   loop: true,
    // });
  },
  destroyed: function () {
    console.log("destroyed");
    // 每次离开当前界面时，清除定时器
    // clearInterval(this.timer);
    // this.timer = null;
  },
};
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
  background-color: #000;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  position: relative;
  .bgbox {
    width: 100%;
    overflow: hidden;
    height: 1080px;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.menubox {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #010a06;
  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.titlebar {
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  .logo {
    margin-left: 8px;
    margin-top: 8px;
  }
  .menulist {
    margin-top: 8px;
  }
  .mobile {
    display: flex;
    width: 100%;
    justify-content: start;
    padding: 5px;
    .menu {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.menulist {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  .navbg {
    background: url("./assets/sy/sy_21.png") top left no-repeat;
    width: 135px;
    height: 55px;
    overflow: hidden;
    line-height: 200px;
    background-position-y: 0;
    margin-top: 13px;
    cursor: pointer;
  }
  .navbg:hover {
    background-position-y: -55px;
  }
  .shouye {
    width: 77px;
  }
  .xianqing {
    background-position: -77px 0;
  }
  .xianqing:hover {
    background-position: -78px -56px;
  }
  .juese {
    background-position: -212px 0;
  }
  .mhgushi {
    background-position: -347px 0;
  }
}
.menubox .menulist {
  padding-top: 80px;
  flex-direction: column;
  align-items: center;
}

.el-footer {
  background-color: #010a06;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  min-height: 800px;
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 30px;
  position: relative;
  padding: 0;
}

.mainview {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
}
.grid-content {
  min-height: 36px;
}
</style>
