import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{index:1,title: '当火车鸣笛3秒'},
    component: Home,
  },
  {
    path: '/rolelist',
    name: 'RoleList',
    meta:{index:2,title: '角色介绍-当火车鸣笛3秒'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rolelist" */ '../components/RoleList.vue')
  },
  {
    path: '/playway',
    name: 'PlayWay',
    meta:{index:3,title: '玩法介绍-当火车鸣笛3秒'},
    component: () => import(/* webpackChunkName: "playway" */'../components/PlayWay.vue')
  },
  {
    path: '/backstory',
    name: 'BackStory',
    meta:{index:4,title: '幕后故事-当火车鸣笛3秒'},
    component: () => import(/* webpackChunkName: "backstory" */'../components/BackStory.vue')
  },
  {
    path: '/story/detail/:storyid',
    name: 'StoryDetail',
    meta:{index:5,title: '幕后故事-当火车鸣笛3秒'},
    component: () => import(/* webpackChunkName: "storydetail" */'../components/StoryDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
